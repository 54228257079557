<template>
  <h1 class="h2 indexPanel-title">會員登入</h1>
  <Form class="form" @submit="onSubmit" :validation-schema="schema">
    <FormInput field-name="username" field-label="帳號(公司統一編號/個人身份證字號)" />
    <FormInput field-name="password" field-label="密碼" field-type="password"/>
    <RouterLink style="float:right;" to="/forgetpassword">忘記密碼</RouterLink>
    <CaptchaInput :captcha-value="captcha?.value" @refresh-captcha="refreshCaptch"/>
    <button type="submit" class="btn btn-primary btn-full">登入</button>
  </Form>
  <div class="form-item otherLink">
    <RouterLink to="/schedule?site=cultural"><i class="icon icon-calendar" aria-hidden="true"></i>文化館<br>檔期行事曆</RouterLink>
    <RouterLink to="/schedule?site=concert"><i class="icon icon-calendar" aria-hidden="true"></i>表演廳<br>檔期行事曆</RouterLink>
    <RouterLink to="/signup"><i class="icon icon-user-plus" aria-hidden="true"></i>會員申請</RouterLink>
    <a href="https://tmc.taipei/" target="_blank" title="回官網(另開視窗)"><i class="icon icon-home" aria-hidden="true"></i>回官網</a>
  </div>
  <div class="form-item">
    <p class="text-danger text-center">僅申請表演廳、文化館特展廳/大廳者，需辦理會員申請</p>
  </div>
</template>
<script>
import { mapActions } from 'pinia';
import { useUserData } from '@/store/user';
import { login as schema } from '@/utils/schema';
import FormInput from '@/components/Input/FormInput.vue';
import CaptchaInput from '@/components/Input/CaptchaInput.vue';
import { Form } from 'vee-validate';
import { useLoadingScreen } from '@/store/index';
import api from '@/api/api';

export default {
  components: {
    Form,
    FormInput,
    CaptchaInput,
  },
  data() {
    return {
      schema,
      captcha: null,
    };
  },
  methods: {
    async onSubmit(formData) {
      this.setLoading(true);
      const captchaValid = await api.checkCaptcha({ key: this.captcha.key, value: formData.captcha });
      this.setLoading(false);
      if (!captchaValid) {
        alert('驗證碼錯誤，請重新輸入');
        this.refreshCaptch();
        return;
      }

      const isPassed = await this.login(formData);
      if (!isPassed) this.refreshCaptch();
    },
    refreshCaptch() {
      this.captcha = null;
      api.getCaptcha().then((data) => {
        this.captcha = data;
      });
    },
    ...mapActions(useLoadingScreen, ['setLoading']),
    ...mapActions(useUserData, ['login']),
  },
  created() {
    this.refreshCaptch();
  },
};
</script>
