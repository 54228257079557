<template>
  <div class="form-captcha">
    <label class="form-label is-required">驗證碼</label>
    <div class="form-captcha-group">
      <Field name="captcha" v-slot="{ field, errorMessage }">
        <input
          class="form-input"
          v-bind="field"
          :class="{ 'is-invalid': !!errorMessage }"
          placeholder="請輸入驗證碼"
          type="text"
        />
      </Field>
      <div class="form-captcha-tool">
        <div id="captcha-container" class="form-captcha-img">
          <canvas v-show="captchaValue" ref="canvas" id="captcha" style="width: 100%; height: 100%" />
        </div>
        <button type="button" class="btn btn-dark" aria-label="重新產生驗證碼" @click="refreshCode">
          <i class="icon icon-refresh" aria-hidden="true"></i>
        </button>
        <button type="button" class="btn btn-dark" aria-label="語音驗證碼" @click="speakCode">
          <i class="icon icon-audio" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="form-promp is-invalid">
      <ErrorMessage name="captcha" />
    </div>
  </div>
</template>
<script>
import { Field, ErrorMessage } from 'vee-validate';

export default {
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    captchaValue: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      ctx: null,
    };
  },
  methods: {
    drawCode() {
      const $canvas = this.$refs.canvas;
      const { ctx } = this;

      if (this.captchaValue === null) return;

      const fontsize = 30;
      $canvas.width = fontsize * this.captchaValue.length + 20;
      $canvas.height = fontsize + 20;

      function getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i += 1) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }

      // 圖片混淆
      for (let i = 0; i < 10; i += 1) {
        ctx.strokeStyle = getRandomColor();
        ctx.beginPath();
        ctx.moveTo(Math.random() * $canvas.width, Math.random() * $canvas.height);
        ctx.lineTo(Math.random() * $canvas.width, Math.random() * $canvas.height);
        ctx.stroke();
      }

      ctx.font = `${fontsize}px Georgia bold`;
      for (let i = 0; i < this.captchaValue.length; i += 1) {
        const c = this.captchaValue.charAt(i);
        const x = 10 + i * fontsize;
        const y = fontsize + Math.random() * 10;
        ctx.fillStyle = getRandomColor();
        ctx.fillText(c, x, y);
      }
    },
    speakCode() {
      if ('speechSynthesis' in window) {
        const synth = window.speechSynthesis;
        const utterance = new SpeechSynthesisUtterance(this.captchaValue);
        utterance.lang = 'zh-TW';
        synth.speak(utterance);
      }
    },
    refreshCode() {
      this.$emit('refreshCaptcha');
    },
  },
  watch: {
    captchaValue() {
      this.drawCode();
    },
  },
  mounted() {
    const $canvas = this.$refs.canvas;
    this.ctx = $canvas.getContext('2d');

    this.drawCode();
  },
};
</script>
